<template>
  <!--begin::auth-template-->
  <!--begin::Login-->
  <div
    id="nyg_auth"
    class="login login-signin-on d-flex flex-column flex-lg-row flex-column-fluid"
  >
    <!--begin::Content-->
    <div
      class="d-flex login-content order-2 order-lg-1 flex-column position-relative p-7 overflow-hidden"
    >
      <div class="d-flex flex-column-fluid flex-center mt-20 mt-md-30 mt-lg-0">
        <router-view />
      </div>
    </div>
    <!--end::Content-->
    <!--begin::Aside-->
    <div
      class="
        position-relative
        login-aside
        aside-img
        order-1
        order-lg-2
        flex-row-fluid
        p-10
        p-lg-15
        aside-bg-color
        bgi-size-cover bgi-position-center
      "
      :style="`background-image: url('${ backgroundImage }');`"
    >
      <!--begin: Aside Container -->
      <div class="d-flex flex-row-fluid flex-column justify-content-between">
        <!--begin: Aside header -->
        <!-- <Logo :width="150" text-color="#fff" dot-color="#ffce01" /> -->
        <div id="login_logo">
          <img src="/media/custom/login_logo.svg" />
        </div>
        <!--end: Aside header -->
      </div>
      <!--end: Aside Container -->
    </div>
    <!--end::Aside-->
  </div>
  <!--end::Login-->
  <!--end::auth-template-->
</template>

<script lang="ts">
  import { defineComponent, computed, onMounted, onUnmounted } from 'vue'
  import { useStore } from 'vuex'
  import { Actions } from '@src/store/enums/StoreEnums'

  export default defineComponent({
    name: 'AuthLogin',
    setup () {
      const store = useStore()
      const errors = computed(() => store.state.auth.errors)

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */ // TODO: Fix process.env error in ts
      const backgroundImage = computed(() => `${ process.env.BASE_URL }media/custom/login_bg.jpg`)

      onMounted(() => {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, 'bg-body')
      })

      onUnmounted(() => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'bg-body')
      })

      return {
        errors,
        backgroundImage
      }
    }
  })
</script>

<!-- Load login custom page styles -->
<style lang="scss">
  @import "~@src/assets/sass/pages/auth.scss";
</style>
