module.exports = {
  aside: {
    users: {
      all: 'Alle brukere',
      add: 'Legg til bruker'
    },
    items: {
      all: 'Alle enhetene',
      add: 'Legg til enhet',
      templates: 'Alle malene',
      addTemplate: 'Legg til mal'
    },
    groups: {
      all: 'Alle gruppene',
      add: 'Lag ny gruppe'
    },
    contactPersons: {
      all: 'Alle kontaktpersonene',
      add: 'Legg til kontaktperson'
    },
    integrations: {
      wp: {
        sync: 'WP Synkronisering'
      }
    }
  }
}
