module.exports = {
  breadcrumb: 'Innstillinger',
  general: {
    toolbar: {
      title: 'Generelle innstillinger for applikasjonen'
    }
  },
  appearance: {
    toolbar: {
      title: 'Tilpass hvordan applikasjonen ser ut'
    }
  },
  integrations: {
    toolbar: {
      title: 'Konfigurer integrasjoner med andre systemer'
    }
  },
  forms: {
    global: {
      title: 'Globale innstillinger',
      fields: {
        showGroups: {
          value: {
            title: 'Vis gruppeoppsett',
            description: 'Skal de forskjellige oppsettene i hver gruppe vises?'
          }
        },
        showFilters: {
          value: {
            title: 'Vis filter',
            description: 'Skal filter vises? Velg også hvilke felt som skal vises?',
          },
          options: {
            availability: {
              value: {
                title: 'Tilgjengelighet',
                description: 'Skal filter for tilgjengelighet vises?'
              }
            },
            price: {
              value: {
                title: 'Pris',
                description: 'Skal filter for pris vises?'
              }
            },
            bedrooms: {
              value: {
                title: 'Soverom',
                description: 'Skal filter for soverom vises?'
              }
            },
            usableFloorArea: {
              value: {
                title: 'BRA',
                description: 'Skal filter for BRA vises?'
              }
            },
            plotArea: {
              value: {
                title: 'Tomteareal',
                description: 'Skal filter for tomteareal vises?'
              }
            },
            priceExtLink: {
              value: {
                title: 'Ekstern lenke',
                description: 'Viser ekstern lenke'
              }
            },
            primaryFloorArea: {
              value: {
                title: 'Primærrom',
                description: 'Skal filter for primærrom vises?'
              }
            },
            floor: {
              value: {
                title: 'Etasje',
                description: 'Skal filter for etasje vises?'
              }
            }
          }
        },
        showCarousel: {
          value: {
            title: 'Vis karusell',
            description: 'Skal karusell vises? Velg også hvilken informasjon som skal vises',
          },
          options: {
            availability: {
              value: {
                title: 'Tilgjengelighet',
                description: 'Skal tilgjengelighet vises?'
              }
            },
            price: {
              value: {
                title: 'Pris',
                description: 'Skal pris vises?'
              }
            },
            bedrooms: {
              value: {
                title: 'Soverom',
                description: 'Skal soverom vises?'
              }
            },
            usableFloorArea: {
              value: {
                title: 'BRA',
                description: 'Skal BRA vises?'
              }
            },
            plotArea: {
              value: {
                title: 'Tomteareal',
                description: 'Skal tomteareal vises?'
              }
            },
            primaryFloorArea: {
              value: {
                title: 'Primærrom',
                description: 'Skal primærrom vises?'
              }
            },
            floor: {
              value: {
                title: 'Etasje',
                description: 'Skal etasje vises?'
              }
            },
            priceExtLink: {
              value: {
                title: 'Ekstern lenke',
                description: 'Viser ekstern lenke'
              }
            }
          }
        },
        showTableSorting: {
          value: {
            title: 'Tabellsortering',
            description: 'Skal sorteringsalternativer for tabellen vises?'
          },
          options: {
            name: {
              value: {
                title: 'Navn',
                description: 'Viser navnet'
              }
            },
            price: {
              value: {
                title: 'Pris',
                description: 'Viser prisen'
              }
            },
            bedrooms: {
              value: {
                title: 'Soverom',
                description: 'Viser antall soverom'
              }
            },
            usableFloorArea: {
              value: {
                title: 'BRA',
                description: 'Viser BRA'
              }
            },
            primaryFloorArea: {
              value: {
                title: 'Primærrom',
                description: 'Viser primærrom'
              }
            },
            floor: {
              value: {
                title: 'Etasje',
                description: 'Viser etasjen'
              }
            },
            floors: {
              value: {
                title: 'Etasjer',
                description: 'Viser antall etasjer'
              }
            },
            balcony: {
              value: {
                title: 'Balkong',
                description: 'Viser balkong'
              }
            },
            priceExtLink: {
              value: {
                title: 'Ekstern lenke',
                description: 'Viser ekstern lenke'
              }
            },
            plotArea: {
              value: {
                title: 'Tomteareal',
                description: 'Viser tomteareal'
              }
            }
          }
        },
        showTable: {
          value: {
            title: 'Vis tabell',
            description: 'Skal tabellen vises? Velg også hvilke felt som skal vises og hvilken rekkefølge de skal vises i.' // eslint-disable-line
          },
          options: {
            name: {
              value: {
                title: 'Navn',
                description: 'Viser navnet'
              }
            },
            price: {
              value: {
                title: 'Pris',
                description: 'Viser prisen'
              }
            },
            bedrooms: {
              value: {
                title: 'Soverom',
                description: 'Viser antall soverom'
              }
            },
            usableFloorArea: {
              value: {
                title: 'BRA',
                description: 'Viser BRA'
              }
            },
            primaryFloorArea: {
              value: {
                title: 'Primærrom',
                description: 'Viser primærrom'
              }
            },
            floor: {
              value: {
                title: 'Etasje',
                description: 'Viser etasjen'
              }
            },
            floors: {
              value: {
                title: 'Etasjer',
                description: 'Viser antall etasjer'
              }
            },
            balcony: {
              value: {
                title: 'Balkong',
                description: 'Viser balkong'
              }
            },
            priceExtLink: {
              value: {
                title: 'Ekstern lenke',
                description: 'Viser ekstern lenke'
              }
            },
            plotArea: {
              value: {
                title: 'Tomteareal',
                description: 'Viser tomteareal'
              }
            }
          }
        }
      },
      success: {
        title: 'Innstillingen ble oppdatert!',
        message: ':settingkey ble oppdatert'
      }
    },
    integrations: {
      title: 'Integrasjoner',
      btn: 'Lagre',
      subSections: {
        wp: {
          title: 'Wordpress',
          description: 'Her kan du sette opp integrasjonen mot Wordpress hvis pluginen Housing Prospectus benyttes'
        },
        sg: {
          title: 'Sendgrid',
          description: 'Her kan du sette opp integrasjonen mot Sendgrid for å sende e-poster'
        },
        make: {
          title: 'Make',
          description: 'Her kan du sette opp integrasjonen mot Make for nyhetsbrev'
        }
      },
      fields: {
        wp: {
          wpEndpoint: {
            title: 'URL til Wordpress websiden',
            placeholder: 'https://www.addressen-til-WP-websiden.no',
            errors: {
              url: 'URLen til Wordpress siden må være en gyldig URL'
            }
          },
          syncUrl: {
            title: 'URL til Wordpress sync API endpoint',
            placeholder: 'https://www.addressen-til-WP-websiden.no/wp-json/nyghp/v1/start',
            errors: {
              url: 'API endpoint addressen må være en gyldig URL'
            }
          }
        },
        sg: {
          apiKey: {
            title: 'API nøkkel',
            placeholder: 'Hemmelig API nøkkel for Sendgrid',
            errors: {
              required: 'API nøkkelen kan ikke være tom'
            }
          },
          mailinglistIds: {
            title: 'Mailingliste IDer',
            placeholder: 'IDene til mailinglistene du vil sende e-post til, separert med komma',
            errors: {
              required: 'Mailingliste IDene kan ikke være tom'
            }
          },
          senderEmail: {
            title: 'Avsenders e-post',
            placeholder: 'E-post adressen til avsenderen',
            alert: 'Husk at avsender må godkjennes i Sendgrid før de kan sende e-post',
            errors: {
              required: 'Avsenders e-post kan ikke være tom',
              email: 'Avsenders e-post må være en gyldig e-post adresse'
            }
          },
          senderName: {
            title: 'Avsenders navn',
            placeholder: 'Navnet til avsenderen',
            errors: {
              required: 'Avsenders navn kan ikke være tom'
            }
          }
        },
        make: {
          userId: {
            title: 'Bruker ID',
            placeholder: 'Bruker ID for Make',
            errors: {
              required: 'Bruker IDen kan ikke være tom'
            }
          },
          apiKey: {
            title: 'API nøkkel',
            placeholder: 'Hemmelig API nøkkel for Make',
            errors: {
              required: 'API nøkkelen kan ikke være tom'
            }
          },
          mailinglistId: {
            title: 'Mailingliste ID',
            placeholder: 'IDen til mailinglisten du vil sende nyhetsbrev til',
            errors: {
              required: 'Mailingliste IDen kan ikke være tom'
            }
          }
        }
      },
      toasts: {
        wp: {
          success: {
            title: 'Wordpress integrasjonen ble oppdatert',
            message: 'URLen til Wordpress API endpoint ble oppdatert'
          },
          error: {
            title: 'Wordpress integrasjonen ble ikke oppdatert',
            message: 'URLen til Wordpress API endpoint ble ikke oppdatert'
          }
        },
        // Sendgrid
        sg: {
          success: {
            title: 'Sendgrid integrasjonen ble oppdatert',
            message: 'Innstillingene til Sendgrid ble oppdatert'
          },
          error: {
            title: 'Sendgrid integrasjonen ble ikke oppdatert',
            message: 'Innstillingene til Sendgrid ble ikke oppdatert'
          }
        },
        make: {
          success: {
            title: 'Make integrasjonen ble oppdatert',
            message: 'Innstillingene til Make ble oppdatert'
          },
          error: {
            title: 'Make integrasjonen ble ikke oppdatert',
            message: 'Innstillingene til Make ble ikke oppdatert'
          }
        }
      }
    },
    app: {
      title: 'Applikasjonsinnstillinger',
      btn: 'Lagre',
      fields: {
        name: {
          title: 'Navn',
          placeholder: 'Skriv inn navnet på applikasjonen',
          description: 'Dette navnet vil vises f.eks i brødsmulestien i applikasjonen',
          errors: {
            minLength: 'Navnet må være lenger enn ett tegn'
          }
        },
      },
      success: {
        title: 'APP-innstillingen ble oppdatert',
        message: ':appsettingkey ble oppdatert'
      }
    },
    modals: {
      title: 'Modalinnstillinger',
      description: 'Her kan du endre innstillingene for modaler i applikasjonen. Du kan endre farge, størrelse og animasjon.', // eslint-disable-line
      fields: {
        template: {
          title: 'Modalmal',
          description: 'Velg en mal for modalen'
        },
      },
      success: {
        title: 'Modalinnstillingen ble oppdatert',
        message: 'Modaltemplatet ble oppdatert til: :modaltemplate'
      }
    },
    colors: {
      title: 'Fargeinnstillinger',
      description: 'Her kan du endre fargeinnstillingene for applikasjonen. De forskjellige fargene vil brukes på de forskjellige statusene for elementene.', // eslint-disable-line
      subtitles: {
        mainColors: {
          title: 'Hovedfarger',
          description: 'Fargene som brukes på de forskjellige statusene for elementene (tekst, bakgrunn, knapper, osv)'
        },
        colorAreas: {
          title: 'Fargeområder',
          description: 'Fargene som brukes på de forskjellige statusene for elementene på de klikkbare områdene'
        }
      },
      properties: {
        primary: 'Primærfarge',
        secondary: 'Sekundærfarge',
        color: 'Strekfarge',
        colorActive: 'Strekfarge ved musover',
        fillColor: 'Fyllfarge',
        fillColorActive: 'Fyllfarge ved musover',
        lOpacity: 'Fyllfarge gjennomsiktighet',
        lOpacityActive: 'Fyllfarge gjennomsiktighet ved musover',
        weight: 'Strekbredde',
        weightActive: 'Strekbredde ved musover',
      },
      fields: {
        primary: {
          title: 'Primærfarge'
        },
        secondary: {
          title: 'Sekundærfarge'
        },
        color: {
          title: 'Strekfarge'
        },
        colorActive: {
          title: 'Strekfarge ved musover'
        },
        fillColor: {
          title: 'Fyllfarge'
        },
        fillColorActive: {
          title: 'Fyllfarge ved musover'
        },
        lOpacity: {
          title: 'Fyllfarge gjennomsiktighet'
        },
        lOpacityActive: {
          title: 'Fyllfarge gjennomsiktighet ved musover'
        },
        weight: {
          title: 'Strekbredde'
        },
        weightActive: {
          title: 'Strekbredde ved musover'
        }
      }
    }
  }
}
