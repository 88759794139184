<template>
  <router-view></router-view>
</template>

<script lang="ts">
  import { defineComponent, nextTick, onMounted } from 'vue'
  import { useStore } from 'vuex'
  import { Mutations } from '@src/store/enums/StoreEnums'
  import { initializeComponents } from '@src/core/plugins/keenthemes'

  export default defineComponent({
    name: 'App',
    setup () {
      const store = useStore()

      onMounted(() => {
        /**
         * this is to override the layout config using saved data from localStorage
         * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
         */
        store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG)

        nextTick(() => {
          initializeComponents()
        })
      })
    }
  })
</script>

<style lang="scss">
  // Global styles
  @import '@src/assets/sass/style';
</style>
