import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "nyg_auth",
  class: "login login-signin-on d-flex flex-column flex-lg-row flex-column-fluid"
}
const _hoisted_2 = { class: "d-flex login-content order-2 order-lg-1 flex-column position-relative p-7 overflow-hidden" }
const _hoisted_3 = { class: "d-flex flex-column-fluid flex-center mt-20 mt-md-30 mt-lg-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_view)
      ])
    ]),
    _createElementVNode("div", {
      class: "position-relative login-aside aside-img order-1 order-lg-2 flex-row-fluid p-10 p-lg-15 aside-bg-color bgi-size-cover bgi-position-center",
      style: _normalizeStyle(`background-image: url('${ _ctx.backgroundImage }');`)
    }, _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "d-flex flex-row-fluid flex-column justify-content-between" }, [
        _createElementVNode("div", { id: "login_logo" }, [
          _createElementVNode("img", { src: "/media/custom/login_logo.svg" })
        ])
      ], -1)
    ]), 4)
  ]))
}