module.exports = {
  index: {
    breadcrumb: 'Alle gruppene',
    search: {
      title: 'Sorter etter:',
      placeholder: 'Søk etter grupper...'
    },
    toolbar: {
      export: {
        title: 'Eksporter'
      },
      addCustomer: {
        title: 'Legg til gruppe'
      }
    }
  },
  view: {
    title: 'Gruppedetaljer',
    image: {
      missing: 'Mangler bilde'
    }
  },
  edit: {
    deleted: {
      title: 'Denne gruppen er slettet',
      message: ':authuser slettet denne gruppen :datetime'
    },
    image: {
      upload: {
        success: {
          title: 'Bildet ble oppdatert',
          message: 'Bildet til gruppen ble oppdatert'
        },
        error: {
          title: 'Det oppstod en feil',
          message: 'En feil oppstod når bildet skulle oppdateres: :error'
        }
      }
    },
    modals: {
      setAsDefault: {
        title: 'Sett standardgruppe',
        subTitle: 'Definer boligvelgerens hovedvisning',
        message: 'Standardgruppen er den som vises når noen besøker hovedsiden til boligvelgeren ("/boligvelger"). Hvis du setter gruppen ":itemname" som standard, vil standard-oppsettet i denne gruppen lastes inn som standard boligvelger visning.', // eslint-disable-line max-len
        btn: {
          title: 'Sett som standard'
        },
        toasts: {
          success: {
            title: 'Standardgruppen ble oppdatert oppdatert',
            message: 'Gruppen ":name" er nå standardgruppe for boligvelgeren'
          }
        }
      }
    },
    leaflet: {
      popup: {
        title: 'Enhetskobling',
        btn: 'Gå til enhet',
        unit: {
          desc: 'Velg en enhet',
          selectForm: {
            title: 'Velg en enhet'
          }
        },
        action: {
          desc: 'Velg en handling',
          options: {
            external: {
              title: 'Ekstern lenke',
            },
            wpLink: {
              title: 'WP side'
            },
            modal: {
              title: 'Modal'
            }
          }
        },
        externalUrl: {
          title: 'Ekstern lenke',
          placeholder: 'Skriv inn en ekstern lenke',
        },
        success: {
          title: 'Enheten ble linket',
          message: 'Enheten ble koblet til elementet'
        },
        error: {
          title: 'Enheten ble ikke linket',
          message: 'Det oppstod en feil da enheten skulle linkes: :error'
        }
      },
      addItem: {
        title: 'Elementet ble lagt til',
        message: 'Elementet ble lagt til i gruppen :name'
      },
      editItems: {
        title: 'Elementer ble oppdatert',
        message: ':count elementer i gruppen :name ble oppdatert i databasen'
      },
      deletedItems: {
        title: 'Elementer ble slettet',
        message: ':count elementer i gruppen :name ble slettet fra databasen'
      }
    }
  },
  setups: {
    index: {
      title: 'Alle oppsettene',
      noSetups: 'Ingen oppsett funnet - kom igang ved å tykke på "Lag nytt oppsett" knappen'
    },
    addNew: {
      button: 'Lag nytt oppsett'
    },
    edit: {
      details: {
        title: 'Informasjon',
        form: {
          name: {
            title: 'Navn'
          }
        },
        toolbar: {
          btn: {
            title: 'Sett som standard',
            active: 'Standardoppsett'
          }
        },
        list: {
          title: 'Enheter i oppsettet',
          description: 'Her bestemmes rekkefølgen for hvordan elementene vises frontend. Drag\'n\'drop!',
          noItems: 'Ingen enheter funnet',
          notLinked: '(ikke koblet mot enhet)'
        },
        modals: {
          setAsDefault: {
            title: 'Sett standardoppsett',
            subTitle: 'Definer boligvelgerens hovedvisning',
            message: 'Standardoppsettet er det som vises når noen besøker hovedsiden til boligvelgeren ("/boligvelger") hvis gruppen som oppsettet ligger i også er standard. Hvis du setter oppsettet ":itemname" som standard, vil det lastes inn som standard boligvegler visning.', // eslint-disable-line max-len
            btn: {
              title: 'Sett som standard'
            },
            toasts: {
              success: {
                title: 'Standardoppsett oppdatert',
                message: 'Oppsettet ":name" er nå standardoppsett for denne gruppen'
              }
            }
          }
        }
      },
      fields: {
        title: 'Felt'
      },
      delete: {
        title: 'Slett dette oppsettet',
        alert: {
          title: 'Viktig informasjon ved sletting av et oppsett',
          message: 'Alle enhetene som er linket til dette oppsettet vil bli fjernet og miste sin kobling.'
        },
        btn: {
          title: 'Slett oppsettet'
        },
        modal: {
          message: 'Er du sikker på at du vil slette oppsettet ":itemname"?'
        },
        toasts: {
          deleted: {
            title: 'Gruppeoppsettet ble slettet!',
            message: ':name oppsettet ble fjernet fra gruppen.'
          }
        }
      }
    },
    modal: {
      add: {
        title: 'Lag et nytt gruppeoppsett',
        form: {
          name: {
            title: 'Navn',
            placeholder: 'F.eks "sør-vest", "nord-vest", "forsiden", "baksiden" etc...',
            description: 'Bør være relatert til hva som vises i oppsettet',
            errors: {
              required: 'Oppsettet må ha et navn',
              minLength: 'Navnet må være lenger enn 2 bokstaver',
              alphaAndHyphenAndApostrophe: 'Navnet kan kun bestå av bokstaver og tall'
            }
          }
        }
      }
    }
  },
  forms: {
    create: {
      title: 'Lag ny gruppe',
      fields: {
        name: {
          title: 'Navn',
          placeholder: 'Skriv inn navnet på gruppen',
          errors: {
            required: 'Gruppen må ha et navn',
            minLength: 'Navnet må være lenger enn 2 bokstaver',
            alphaAndHyphenAndApostrophe: 'Gruppenavn kan kun bestå av bokstaver og tall'
          }
        },
        type: {
          title: 'Type',
          placeholder: 'Velg en type gruppe',
          errors: {
            required: 'Gruppen må ha en type'
          }
        }
      },
      success: {
        title: 'Gruppen ble opprettet',
        message: ':groupname ble opprettet'
      }
    },
    edit: {
      title: 'Gruppeinformasjon',
      fields: {
        name: {
          title: 'Navn',
          placeholder: 'Skriv inn gruppens navn',
          errors: {
            required: 'Gruppen må ha et navn',
            minLength: 'Gruppenavnet må være lenger enn 2 bokstaver',
            alphaAndHyphenAndApostrophe: 'Gruppenavnet kan kun bestå av bokstaver og tall'
          }
        },
        type: {
          title: 'Type',
          placeholder: 'Velg en type',
          errors: {
            required: 'Gruppen må ha en type',
            alphaAndHyphenAndApostrophe: 'Gruppenavnet kan kun bestå av bokstaver og tall'
          }
        }
      },
      success: {
        title: 'Gruppen ble oppdatert',
        message: ':group ble oppdatert'
      }
    },
    delete: {
      title: 'Deaktiver gruppen',
      btn: 'Deaktiver gruppen',
      btnDelete: 'Slett gruppen',
      warning: {
        title: 'Viktig ved sletting/deaktivering av grupper',
        message: 'Hvis en gruppe deaktiveres vil den ikke lenger være synlig i boligvelgeren, men den kan gjenopprettes senere. Hvis en gruppe slettes vil den ikke kunne gjenopprettes, alle oppsettene under gruppen vil slettes og alle enhetene som er koblet til oppsettene vil miste sin kobling.' // eslint-disable-line max-len
      },
      checkbox: {
        message: 'Slett gruppen permanent'
      },
      modal: {
        deleted: {
          title: 'Slett gruppen',
          message: 'Er du helt sikker på at du vil slette gruppen :name?', // eslint-disable-line max-len
        },
        disabled: {
          title: 'Deaktiver gruppen',
          message: 'Er du helt sikker på at du vil deaktivere gruppen :name?'
        }
      },
      toasts: {
        deleted: {
          title: 'Gruppen ble slettet',
          message: 'Gruppen :name ble slettet'
        },
        disabled: {
          title: 'Gruppe ble deaktivert',
          message: 'Gruppen :name ble deaktivert'
        }
      }
    },
    reactivate: {
      title: 'Aktiver gruppen',
      btn: 'Aktiver gruppen',
      message: {
        title: 'Informasjon om deaktiverte grupper',
        message: 'Denne gruppen er deaktivert. Når en gruppe er deaktivert, vil den ikke vises i boligvelgeren.'
      }
    }
  }
}
