module.exports = {
  index: {
    breadcrumb: 'Alle kontaktpersonene',
    search: {
      title: 'Sorter etter:',
      placeholder: 'Søk etter kontaktpersoner...'
    },
    table: {
      noContactsFound: 'Ingen kontaktpersoner funnet',
    },
    toolbar: {
      export: {
        title: 'Eksporter'
      },
      addCustomer: {
        title: 'Legg til kontaktperson'
      }
    }
  },
  view: {
    title: 'Kontaktdetaljer',
    lastLogin: 'Sist innlogget',
  },
  edit: {
    deleted: {
      title: 'Denne kontaktpersonen er slettet',
      message: ':authuser slettet denne kontaktpersonen :datetime'
    }
  },
  forms: {
    create: {
      title: 'Legg til kontaktperson',
      fields: {
        name: {
          title: 'Navn',
          placeholder: 'Skriv inn kontaktpersonens navn',
          errors: {
            required: 'Kontaktpersonen må ha et navn',
            minLength: 'Navnet må være lenger enn 2 bokstaver',
            alphaAndHyphenAndApostrophe: 'Kontaktens navn kan kun bestå av bokstaver og tall'
          }
        },
        position: {
          title: 'Stilling',
          placeholder: 'Skriv inn kontaktpersonens stilling',
          errors: {
            minLength: 'Stillingen må være lenger enn 2 bokstaver',
            alphaAndHyphenAndApostrophe: 'Stillingen kan kun bestå av bokstaver og tall'
          }
        },
        email: {
          title: 'E-post',
          placeholder: 'Skriv inn kontaktpersonens epost',
          errors: {
            required: 'Kontaktpersonen må ha en epost',
            email: 'Eposten må være gyldig'
          }
        },
        phone: {
          title: 'Telefonnummer',
          placeholder: 'Skriv inn kontaktpersonens telefonnummer',
          errors: {
            required: 'Kontaktpersonen må ha et telefonnummer',
            numeric: 'Telefonnummeret må være gyldig',

          }
        }
      },
      errorCodes: {
        'auth/email-already-exists': {
          title: 'Eksisterende kontaktperson?',
          message: 'Denne eposten finnes allerede i systemet'
        }
      },
      success: {
        title: 'Kontaktpersonen ble opprettet',
        message: ':contactname ble lagt til som en kontaktperson'
      }
    },
    edit: {
      title: 'Rediger kontaktpersoninformasjon',
      btn: 'Endre passord',
      image: {
        description: 'Klikk på bildet for å endre bildet',
      },
      fields: {
        name: {
          title: 'Navn',
          placeholder: 'Skriv inn kontaktpersonens navn',
          errors: {
            required: 'Kontaktpersonen må ha et navn',
            minLength: 'Navnet må være lenger enn 2 bokstaver',
            alphaAndHyphenAndApostrophe: 'Brukernavn kan kun bestå av bokstaver og tall'
          }
        },
        position: {
          title: 'Stilling',
          placeholder: 'Skriv inn kontaktpersonens stilling',
          errors: {
            minLength: 'Stillingen må være lenger enn 2 bokstaver'
          }
        },
        email: {
          title: 'E-post',
          placeholder: 'Skriv inn kontaktpersonens epost',
          errors: {
            required: 'Kontaktpersonen må ha en epost',
            email: 'Eposten må være gyldig'
          }
        },
        phone: {
          title: 'Telefonnummer',
          placeholder: 'Skriv inn kontaktpersonens telefonnummer',
          errors: {
            required: 'Kontaktpersonen må ha et telefonnummer',
            numeric: 'Telefonnummeret må være gyldig',
            minLength: 'Telefonnummeret må være 8 siffer eller mer'
          }
        }
      },
      errorCodes: {
        'auth/email-already-exists': {
          title: 'Eksisterende kontaktperson?',
          message: 'Denne eposten finnes allerede i systemet'
        },
      },
      success: {
        title: 'Kontaktpersonen ble oppdatert',
        message: ':contactname ble oppdatert'
      }
    },
    delete: {
      title: 'Deaktiver kontoen',
      btn: 'Deaktiver kontoen',
      btnDelete: 'Slett kontoen',
      warning: {
        title: 'Viktig ved sletting/deaktivering av kontaktpersoner',
        message: 'Hvis en kontaktperson deaktiveres, vil ikke kontaktpersonen lenger kunne legges inn som kontaktperson på fremtidige prosjekter. Slettes kontaktpersonen vil informasjon fjernes fra serveren og det vil ikke være mulig å gjenopprette kontaktpersonen senere.' // eslint-disable-line max-len
      },
      checkbox: {
        message: 'Slett kontaktpersonen permanent'
      },
      modal: {
        deleted: {
          title: 'Slett kontaktpersonen',
          message: 'Er du helt sikker på at du vil slette kontaktpersonen til :contactname permanent? All kontaktpersoninformasjon vil bli slettet.', // eslint-disable-line max-len
        },
        disabled: {
          title: 'Deaktiver kontaktpersonen',
          message: 'Er du helt sikker på at du vil deaktivere kontaktpersonen :contactname?'
        }
      },
      toasts: {
        deleted: {
          title: 'Kontaktpersonen ble slettet',
          message: 'Kontoen til :contactname ble slettet'
        },
        disabled: {
          title: 'Kontaktpersonen ble deaktivert',
          message: 'Kontoen til :contactname ble deaktivert'
        }
      }
    },
    reactivate: {
      title: 'Aktiver kontoen',
      btn: 'Aktiver kontoen',
      message: {
        title: 'Informasjon om deaktiverte kontoer',
        message: 'Denne kontoen er deaktivert. Når en konto er deaktivert, kan ikke kontaktpersonen logge inn, men all data relatert til kontoen er fortsatt aktiv. Ved aktivering vil kontaktpersonen kunne logge inn igjen.' // eslint-disable-line max-len
      }
    }
  }
}
