import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions'
import { connectStorageEmulator, getStorage, ref, uploadBytesResumable } from 'firebase/storage'
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut, connectAuthEmulator, getIdToken } from 'firebase/auth' // eslint-disable-line max-len
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import devAppConfig from './appmodes/dev.json'
import prodAppConfig from './appmodes/prod.json'

console.log(`[APP] App is running in ${process.env.NODE_ENV} mode`)
console.log(process.env.NODE_ENV)
const config = process.env.NODE_ENV === 'production' ? prodAppConfig : devAppConfig

const app = initializeApp(config)
const funcs = getFunctions(app, 'europe-west1')
const firestore = getFirestore(app)

if (process.env.VUE_APP_NODE_ENV === 'production') getAnalytics()

const auth = {
  // app,
  getAuth,
  signOut,
  getIdToken,
  onAuthStateChanged,
  signInWithEmailAndPassword
}

const functions = {
  httpsCallable: (funcName = '') => httpsCallable(funcs, funcName)
}

const storage = {
  getStorage,
  ref,
  uploadBytesResumable
}

if (process.env.VUE_APP_LOCAL_DEVELOPMENT === 'enabled') {
  console.info('IMPORTANT: Running local FUNCTIIONS and DATABASE mode')

  // Development @ work
  // Remember to fix apiConfig.ts as well...
  connectAuthEmulator(auth.getAuth(), 'http://localhost:9099', { disableWarnings: true }) // Local AUTH
  connectFirestoreEmulator(firestore, 'localhost', 8081) // Local DB
  connectFunctionsEmulator(funcs, 'localhost', 5001) // Local functions
  connectStorageEmulator(storage.getStorage(), 'localhost', 9199) // Local storage (NEW!)

  // Development @ home
  // Remember to fix apiConfig.ts as well...
  // connectAuthEmulator(auth.getAuth(), 'http://192.168.68.54:9099', { disableWarnings: true }) // Local AUTH
  // connectFirestoreEmulator(firestore, '192.168.68.54', 8081) // Local DB
  // connectFunctionsEmulator(funcs, '192.168.68.54', 5001) // Local functions
}

export default {
  auth,
  functions,
  storage
}
